/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

 /* eslint-disable */


import React,{useState, useEffect} from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios'; 

import WelcomePage from 'containers/WelcomePage/Loadable';
import HomePage from 'containers/HomePage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import OffersPage from 'containers/OffersPage/Loadable';
import ReferralPage from 'containers/ReferralPage/Loadable';
import SubscriptionPage from 'containers/SubscriptionPage/Loadable';
import OfferDetails from 'containers/OfferDetails/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import LevelPage from 'containers/LevelPage/Loadable';
import AdminPage from 'containers/AdminPage/Loadable';
import AllOffers from 'containers/AllOffers/Loadable';
import AllDonations from 'containers/AllDonations/Loadable';

import AllLevels from 'containers/AllLevels/Loadable';
import PlanDetails from 'containers/PlanDetails/Loadable';
import PartnersPage from 'containers/PartnersPage/Loadable';
import PartnerDetails from 'containers/PartnerDetails/Loadable';
import AllPartners from 'containers/AllPartners/Loadable';
import AllEarningPoints from './containers/AllEarningPoints/Loadable';
import EarningPointsDetails from 'containers/EarningPointsDetails/Loadable';
import Faq from 'containers/faqs/Loadable';
import Cancellation from 'containers/cancellation/Loadable';

import AllRewards from 'containers/AllRewards/Loadable';
import RewardsValidation from 'containers/RewardsValidation/Loadable';

import AllUsers from 'containers/AllUsers/Loadable';
import SingleUserDetails from 'containers/SingleUserDetails/Loadable';
import NotificationsPage from 'containers/NotificationsPage/Loadable';

import Admins from 'containers/Admins/Loadable';
import EditAdmin from 'containers/Admins/Edit';
import ParrainsListPage from 'containers/Referral/ParrainsListPage';
import ReferredListPage from 'containers/Referral/ReferredListPage';
import CountryActivationPage from 'containers/Referral/CountryActivationPage';


import vars from './environment';

import AllOffersCategories from 'containers/AllOffersCategories/Loadable';
import ParamsPage from 'containers/ParamsPage/Loadable';
import PointsEarningRulesPage from 'containers/PointsEarningRulesPage/Loadable';
import Footer from './components/Footer';
import Container from './components/Container';

import GlobalStyle from './global-styles';
import Header from './components/Header';
import NotAllowed from './components/NotAllowed';
import {AclProvider} from './context/AclContext';
import AllChallenges from 'containers/AllChallenges/Loadable';
import ChallengeDriverStatus from 'containers/ChallengeDriverStatus/Loadable';
import ChallengePage from 'containers/ChallengePage/Loadable';

import Loader from "react-loader-spinner";

function App() {
  const [loading, setLoading] = useState(true);
  const [adminData, setAdminData] = useState({});
  const [validToken, setValidToken] = useState(true);
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [userFlags, setUserFlags] = useState([]);
  const [adminName, setAdminName] = useState([]);
  const [conPerms, setConPerms] = useState({
    deleteCat: false,
    addCat: false,
    updateEarning: true
  })
  
  const fetchAdmin = () => {
    console.log(process.env.REACT_APP_CONTEXT)
    console.log(process.env.REACT_APP_IMG_BUCKET)
    console.log(process.env)
    axios.post(vars.api+'v1/admin/getByToken', null,{headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }})
      .then(function (response) {
          if(response.status === 200){
            var responseData 
            if(Array.isArray(response.data.data))
              responseData = response.data.data[0]
            else
              responseData = response.data.data
              responseData["services"] = []
              axios.post(vars.api+'v1/services/all', {countryId:'5d87db1af2d2a10748ddc576'},{headers: {'Authorization': `Bearer ${localStorage.getItem('token')}` }})
              .then(function (servicesResponse) {
                responseData["services"] =servicesResponse.data.data
              })
              .catch(function (error) {
                //setValidToken(false)
                //setLoading(false)
                console.log(error);
                
              })
          //   responseData["services"] = [
          //     {
          //        "_id":"5d87db1af2d2a10748ddc573",
          //        "name":"express"
          //     },
          //     {
          //        "_id":"5d87db1af2d2a10748ddc572",
          //        "name":"mobility"
          //     }
          //  ]

           if(responseData.role=="SUPER_ADMIN")
            responseData["permissions"] = [
            {
              "_id":"5d87db1af2d2a10748ddc573",
              "name":responseData.role + "-permissions"
            },
            {
              "_id":"5d87db1af2d2a10748ddc573",
              "name":"ADMIN-permissions"
            }
            ,
            {
              "_id":"5d87db1af2d2a10748ddc573",
              "name":"VIEWER-permissions"
            }
          ]
           else
           responseData["permissions"] = [{
            "_id":"5d87db1af2d2a10748ddc573",
            "name":responseData.role + "-permissions"
          }]



            setUserPermissions(
              responseData["permissions"]
              )

            responseData["regions"] = []

            responseData.countries = responseData.countries.map(x => ({
              ...x,
              name: x.alpha3Code
            }))

            
            setValidToken(true)
            if(responseData.role=="SUPER_ADMIN")
              {
              localStorage.setItem('selectedCountry','5d87db1af2d2a10748ddc576')
              
              axios
                  .post(vars.api + "v1/countries/all" ,
                  {countryId:'5d87db1af2d2a10748ddc576'},
                  {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                  })
                  .then(function (responseCountries) {
                    if(responseCountries.status === 200){
                      responseData.countries = responseCountries.data.data.map(x => ({
                        ...x,
                        name: x.alpha3Code
                      }))
                      console.log(responseData.countries)
                      console.log("responseData.countriesQQQQQQQQQ")
                      setUserFlags(responseData.countries)
                      setAdminData(responseData)
                      setCountriesLoaded(true)
                  }
                  else
                  {
                    setValidToken(false)
                  }
                }
              )
              
            }
            else
            {
              localStorage.setItem('selectedCountry',responseData.countries[0]._id)
              setUserFlags(responseData.countries)
              setAdminData(responseData)
              setCountriesLoaded(true)
            }
            setAdminName(responseData.firstName + " " +responseData.lastName)
            console.log(responseData)
            console.log("responseData")
            setLoading(false)
          }
          else{
            setValidToken(false)
            setLoading(false)
          }
      })
      .catch(function (error) {
        setValidToken(false)
        setLoading(false)
        console.log(error);
      });
  }
  
  useEffect(()=>{
    fetchAdmin()
  },[])

  const  checkPermission = (permissions) => {

    var perm = true;
    if(!!permissions){
      for(var i = 0; i < permissions.length; i++){
        const permTmp = userPermissions.some(el => el.name === permissions[i]);
        //perm = perm && permTmp
        perm = permTmp
        if (perm == true) return true
      }      
    }
    return  perm
  }

  const hasPermission = (permissions, permissionContext) =>{
    var deletePerm = true;
    if(!!permissions){
      for(var i = 0; i < permissions.length; i++){
        const deletePermTmp = userPermissions.some(el => el.name === permissions[i]);
        deletePerm = deletePerm && deletePermTmp
      }      
    }
    return deletePerm
  }

  const PrivateRoute =  ({  component: Component, permissions, ...rest }) => {
    let  permIsGranted =   checkPermission(permissions)

    return (
    <Route
        {...rest}
        render={props =>
          validToken ? (
            <div style={{background: "linear-gradient(to right,#1A0244,#1A0244)"}}>
              {countriesLoaded?
                <>
                <Header props={props} flags={userFlags} adminName={adminName} adminData={adminData}/>
                <Container style={{ 
                  backgroundColor: 'white', 
                  marginTop: -120, borderRadius: 4, 
                  boxShadow: "0px 0px 13px 0px rgba(82, 63, 105, 0.1)", 
                  minHeight: window.innerHeight-260,
                  position: 'relative',
                  padding: '0',
                  //overflowX: 'hidden', 
                  }}>
                  {  permIsGranted
                    ?
                    <Component {...props} adminData = {adminData} />
                    :
                    <NotAllowed 
                      content="Not allowed to access this page! Please contact the SuperAdmin"
                      textColor="#595d6e" 
                      fontSize="24px" 
                      textMargins="10px"
                      iconSize="50px"
                    />
                  }
                </Container>
                <Footer />
                </>
                :
                <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 800,
                      }}
                    >
                      <Loader
                        type="MutatingDots"
                        color="#6227D8"
                        secondaryColor="#F82B77"
                        height={100}
                        width={100}
                        // timeout={3000} //3 secs
                      />
                </div>
                }
             
            </div>
            
          ) : (
            window.location.href.indexOf("localhost")>-1 || window.location.href.indexOf("127.0.0.1")>-1?
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
            :
            <>
            <br/><br/><br/><br/>
            <NotAllowed 
              content="Authentication error! Please reload the page or contact the Admin"
              textColor="#595d6e" 
              fontSize="24px" 
              textMargins="10px"
              iconSize="50px"
            />
            <a
             style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
            }}
             href="#" onClick={() => {
              location.reload()
              }}>Reload
              </a>
            </>
          )
        }
      
    />
    );
  }


  if(loading)
  return (
<div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 800,
      }}
    >
      <Loader
        type="MutatingDots"
        color="#6227D8"
        secondaryColor="#F82B77"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
</div>

  )
  else
    return (
    <div>
      <AclProvider value={userPermissions}>    
      <Router>
        <Switch>
          {/* <Route exact path="/login" component={LoginPage} /> */}
          <Route exact path="/login" component={props => <LoginPage validateToken={fetchAdmin} />} />
       
          {/* <Route 
            exact  */}
            <PrivateRoute 
            exact
            path="/" 
            //component={HomePage} 
            component={SubscriptionPage} 
            //component={WelcomePage} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/home" 
            //component={HomePage} 
            component={SubscriptionPage} 
            //permissions={['dashboard-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/offers" 
            component={OffersPage} 
            //permissions={['offers-create']}
            permissions={['SUPER_ADMIN-permissions']}
          />

          <PrivateRoute 
            path="/refer" 
            component={ReferralPage} 
            //permissions={['offers-create']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/subscription" 
            component={SubscriptionPage} 
            //permissions={['offers-create']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/offerdetails" 
            component={OfferDetails} 
            //permissions={['offers-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/alloffers" 
            component={AllOffers} 
            //permissions={['offers-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />
          {/* 
          <PrivateRoute 
            path="/challengedetails" 
            component={challengeDetails} 
            permissions={['challenges-read']}
          />*/}
          <PrivateRoute 
            path="/challenges" 
            component={ChallengePage} 
            //permissions={['challenge-create']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/allchallenges" 
            component={AllChallenges} 
            //permissions={['challenge-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/driverstatus" 
            component={ChallengeDriverStatus} 
            //permissions={['challenge-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/alldonations" 
            component={AllDonations}
          />

          <PrivateRoute 
            path="/earningpoints" 
            component={PointsEarningRulesPage} 
            //permissions={['earning_points-create', 'earning_points-update']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/allearningpoints" 
            component={AllEarningPoints} 
            //permissions={['earning_points-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/earningpointsdetails" 
            component={EarningPointsDetails} 
            //permissions={['earning_points-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/alllevels" 
            component={()=> <AllLevels 
              //canDelete={hasPermission(['plans-delete'])}
              canDelete={hasPermission(['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions'])}
              adminData={adminData}
              />} 
            //permissions={['plans-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/allrewards" 
            component={AllRewards} 
            //permissions={['rewards-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/rewardsvalidation" 
            component={RewardsValidation} 
            //permissions={['rewards-read', 'rewards-update']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

           <PrivateRoute 
                  path="/faq" 
                  component={Faq} 
                  permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
                />
           <PrivateRoute 
                  path="/cancellation" 
                  component={Cancellation} 
                  permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
                />

          <PrivateRoute 
            path="/levels" 
            component={LevelPage} 
            //permissions={['plans-create','plans-update']}
            permissions={['SUPER_ADMIN-permissions']}
          />

          <PrivateRoute 
            path="/plandetails" 
            component={PlanDetails} 
            //permissions={['plans-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/categories" 
            //component={AllOffersCategories} 
            component={()=> <AllOffersCategories  
              //canDelete={hasPermission(['offer_categories-delete'])} 
              canDelete={hasPermission(['SUPER_ADMIN-permissions','ADMIN-permissions'])} 
              //canCreate={hasPermission(['offer_categories-create'])} 
              canCreate={hasPermission(['SUPER_ADMIN-permissions','ADMIN-permissions'])} 
              adminData={adminData} />} 
            //permissions={['offer_categories-read']}
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions']}
          />

          <PrivateRoute 
            path="/params" 
            component={ParamsPage}
            //permissions={['params-read', 'params-update']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/partners" 
            component={PartnersPage} 
            //permissions={['partners-create', 'partners-update']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/partnerdetails" 
            component={PartnerDetails}
            //permissions={['partners-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/allpartners" 
            component={AllPartners} 
            //permissions={['partners-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/allusers" 
            component={AllUsers} 
            //permissions={['users-read', 'users-update']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            path="/userdetails" 
            component={SingleUserDetails} 
            //permissions={['users-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}

          />

          <PrivateRoute 
            path="/notifications" 
            component={NotificationsPage} 
            //permissions={['users-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}
          />

          <PrivateRoute 
            exact
            path="/admins" 
            component={Admins} 
            //permissions={['admin-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}

          />
         {/*  <PrivateRoute 
            exact
            path="/workspaces" 
            component={Workspaces} 
            //permissions={['admin-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions','VIEWER-permissions']}

          /> */}

          <PrivateRoute 
            path="/admin" 
            component={EditAdmin} 
            //permissions={['admin-read']} 
            permissions={['SUPER_ADMIN-permissions','ADMIN-permissions']}

          />

          <PrivateRoute 
            path="/addadmin" 
            component={AdminPage} 
            //permissions={['admin-read']} 
            permissions={['SUPER_ADMIN-permissions']}

          />

          <PrivateRoute
           path="/referrers/list" 
           component={ParrainsListPage} 
          /> 

          <PrivateRoute 
            path="/referred/list" 
            component={ReferredListPage} 
            // permissions={[]} 
          />

          <PrivateRoute 
            path="/referral/country-activation" 
            component={CountryActivationPage} 
            // permissions={[]} 
          />
        
          <Route path="" component={NotFoundPage} />
        </Switch>
        </Router>
      </AclProvider>
      <GlobalStyle />
    </div>
  );
}

export default App;